import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Formik } from 'formik';

import { responsiveBreakpoints } from 'utils/utils';
import TextField from '../../text-field';
import MyModal from 'components/modals/modal';
import MyRadio from '../../radio';
import { fieldsName } from './constant';
import { addBillingAddressModalSchema } from './validation-schema';
import { selectClientOptionsSelector } from '../../../redux/selectors';
import SelectOld from 'components/select/select.old';
import { getStateData } from 'utils/utils';
import { getCityData } from 'utils/utils';
import { getCountryData } from 'utils/utils';

const AddBillingAddressModal = ({
  open,
  setOpen,
  id,
  initialValueForFields,
  title,
  sendApiRequest,
  countryOptions,
  edit,
  isNotificationAction,
  handleDeclineBtnClick,
}) => {
  const formikRef = useRef(null);
  const { mobile, tablet } = responsiveBreakpoints();
  const [status, setStatus] = useState(true);
  const [countryOpt, setCountryOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);

  useEffect(() => {
    if (clientOptions.additionalSettings?.csc_dropdown == 1) {
      getCountryDataFunc();
      if (initialValueForFields?.country !== '') {
        let payload = {
          country: initialValueForFields?.country,
        };
        getStateDataFunc(payload);
      }
      if (initialValueForFields?.state !== '') {
        let payload = {
          country: initialValueForFields?.country,
          state: initialValueForFields?.state,
        };
        getCityDataFunc(payload);
      }
    }
  }, []);

  const getCountryDataFunc = async () => {
    let data = await getCountryData();
    setCountryOpt(data?.data?.data ?? []);
  };
  const getStateDataFunc = async (payload) => {
    let data = await getStateData({ payload });
    setStateOpt(data?.data?.data ?? []);
  };
  const getCityDataFunc = async (payload) => {
    const data = await getCityData({ payload });
    setCityOpt(data?.data?.data ?? []);
  };

  useEffect(() => {
    if (initialValueForFields && 'status' in initialValueForFields) {
      setStatus(initialValueForFields?.status);
    }
  }, [initialValueForFields]);

  const {
    contactName,
    billingIdCode,
    emailId,
    addressLineOne,
    addressLine2,
    city,
    state,
    zipCode,
  } = fieldsName;

  const saveChangeButtonHandler = () => {
    formikRef.current.submitForm();
  };

  const onSubmitFormikHandler = (values) => {
    let payload = {
      retailerId: id,
      name: values.contactName,
      billingCode: values.billingIdCode,
      email: values.emailId,
      status: status,
      street: values.addressLineOne,
      street2: values.addressLine2,
      city: values.city,
      state: values.state,
      zip: values.zipCode,
      country: values.country,
      billingAddressTableId: values?.billingAddressTableId,
      buyerRequestId: values?.buyerRequestId,
      shippingAddressTableId: values?.shippingAddressTableId,
    };
    console.log('AAAABBBBXXXX', payload);
    sendApiRequest(payload, initialValueForFields?.id, setOpen);
  };

  // Get clientOptions Data
  const clientOptions = useSelector(selectClientOptionsSelector);

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        setOpen(false);
      }}
    >
      Close
    </Button>
  );

  const decline = (
    <Button
      variant='outlined'
      color='error'
      onClick={(e) => {
        e.preventDefault();
        handleDeclineBtnClick();
        setOpen(false);
      }}
    >
      Decline
    </Button>
  );

  const add = (
    <Button variant='contained' onClick={saveChangeButtonHandler}>{`${
      isNotificationAction ? 'Approve' : edit ? 'Save Changes' : 'Add Address'
    }`}</Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      title={title}
      showCloseIcon
      actions={isNotificationAction ? [close, decline, add] : [close, add]}
      sx={{ width: mobile || tablet ? '100%' : '780px', height: '70vh' }}
    >
      <Formik
        innerRef={formikRef}
        validateOnMount
        validationSchema={addBillingAddressModalSchema}
        initialValues={{
          contactName: initialValueForFields?.name,
          billingIdCode:
            initialValueForFields?.billingId ||
            initialValueForFields?.billingCode,
          emailId: initialValueForFields?.email,
          addressLineOne: initialValueForFields?.street,
          addressLine2: initialValueForFields?.street2,
          city: initialValueForFields?.city,
          state: initialValueForFields?.state,
          zipCode: initialValueForFields?.zip,
          country: initialValueForFields?.country,
          // ||
          // countryOpt.find((c) => c.code == 'US')?.code,
          billingAddressTableId: initialValueForFields?.billingAddressTableId,
          buyerRequestId: initialValueForFields?.buyerRequestId,
          shippingAddressTableId: initialValueForFields?.shippingAddressTableId,
        }}
        onSubmit={onSubmitFormikHandler}
      >
        {({ handleSubmit, ...rest }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant='h2'
                    sx={{
                      borderBottom: '1px solid var(--secondary-border-color)',
                      paddingBottom: '2px',
                    }}
                  >
                    Contact Information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Contact Name'
                    placeholder='Enter Contact Name'
                    id={contactName}
                    name={contactName}
                    value={rest.values.contactName}
                    onChange={rest.handleChange}
                    error={
                      rest.touched.contactName &&
                      Boolean(rest.errors.contactName)
                    }
                    helperText={
                      rest.touched.contactName && rest.errors.contactName
                    }
                    sx={
                      initialValueForFields?.billingAddressName &&
                      initialValueForFields?.billingAddressName['color'] ==
                        'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label='Billing ID / CODE'
                    placeholder='Enter Billing ID / Code'
                    id={billingIdCode}
                    name={billingIdCode}
                    value={rest.values.billingIdCode}
                    onChange={rest.handleChange}
                    error={
                      rest.touched.billingIdCode &&
                      Boolean(rest.errors.billingIdCode)
                    }
                    helperText={
                      rest.touched.billingIdCode && rest.errors.billingIdCode
                    }
                    sx={
                      initialValueForFields?.billingAddressBillingId &&
                      initialValueForFields?.billingAddressBillingId['color'] ==
                        'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder='Enter Email Address'
                    label='Email'
                    id={emailId}
                    name={emailId}
                    value={rest.values.emailId}
                    onChange={rest.handleChange}
                    error={rest.touched.emailId && Boolean(rest.errors.emailId)}
                    helperText={rest.touched.emailId && rest.errors.emailId}
                    sx={
                      initialValueForFields?.billingEmail &&
                      initialValueForFields?.billingEmail['color'] == 'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyRadio
                    onChange={setStatus}
                    value={status}
                    row
                    label='Status'
                    options={[
                      { label: 'Active', value: true },
                      { label: 'Inactive', value: false },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant='h2'
                    sx={{
                      borderBottom: '1px solid var(--secondary-border-color)',
                      paddingBottom: '2px',
                    }}
                  >
                    Billing Address
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder='Enter Address Line 1'
                    label='Address Line 1'
                    required
                    id={addressLineOne}
                    name={addressLineOne}
                    value={rest.values.addressLineOne}
                    onChange={
                      clientOptions.additionalSettings?.csc_dropdown == 0
                        ? (e) => {
                            if (
                              e.target.value.length <=
                              clientOptions.additionalSettings
                                ?.street_character_length
                            ) {
                              rest.setFieldValue(
                                addressLineOne,
                                e.target.value
                              );
                              rest.setFieldError(addressLineOne, '');
                              if (e.target.value.length > 1) {
                                rest.setFieldTouched(addressLineOne, true);
                              }
                            } else {
                              rest.setFieldError(
                                addressLineOne,
                                `Address Line 1 cannot exceed ${clientOptions.additionalSettings?.street_character_length} characters`
                              );
                            }
                          }
                        : rest?.handleChange
                    }
                    error={
                      rest.touched.addressLineOne &&
                      Boolean(rest.errors.addressLineOne)
                    }
                    helperText={
                      rest.touched.addressLineOne && rest.errors.addressLineOne
                    }
                    sx={
                      initialValueForFields?.billingAddressStreet &&
                      initialValueForFields?.billingAddressStreet['color'] ==
                        'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder='Enter Address 2'
                    label='Address Line 2'
                    id={addressLine2}
                    name={addressLine2}
                    value={rest.values.addressLine2}
                    onChange={
                      clientOptions.additionalSettings?.csc_dropdown == 0
                        ? (e) => {
                            if (
                              e.target.value.length <=
                              clientOptions.additionalSettings
                                ?.street2_character_length
                            ) {
                              rest.setFieldValue(addressLine2, e.target.value);
                              rest.setFieldError(addressLine2, '');
                              rest.setFieldTouched(addressLine2, true);
                            } else {
                              rest.setFieldError(
                                addressLine2,
                                `Address Line 2 cannot exceed ${clientOptions.additionalSettings?.street2_character_length} characters`
                              );
                            }
                          }
                        : rest?.handleChange
                    }
                    error={
                      rest.touched.addressLine2 &&
                      Boolean(rest.errors.addressLine2)
                    }
                    helperText={
                      rest.touched.addressLine2 && rest.errors.addressLine2
                    }
                    sx={
                      initialValueForFields?.billingAddressStreet2 &&
                      initialValueForFields?.billingAddressStreet2['color'] ==
                        'red'
                        ? { color: 'red' }
                        : {}
                    }
                  />
                </Grid>

                <Grid
                  container
                  sx={{ padding: '16px 0px 23px 20px' }}
                  columnSpacing={3}
                  rowSpacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    {clientOptions.additionalSettings?.csc_dropdown == 0 ? (
                      <TextField
                        placeholder='Enter City'
                        label='City'
                        id={city}
                        name={city}
                        value={rest.values.city}
                        onChange={(e) => {
                          if (
                            e.target.value.length <=
                            clientOptions.additionalSettings
                              ?.city_character_length
                          ) {
                            rest.setFieldValue(city, e.target.value);
                            rest.setFieldError(city, '');
                            rest.setFieldTouched(city, true);
                          } else {
                            rest.setFieldError(
                              city,
                              `City name cannot exceed ${clientOptions.additionalSettings?.city_character_length} characters`
                            );
                          }
                        }}
                        error={rest.touched.city && Boolean(rest.errors.city)}
                        helperText={rest.touched.city && rest.errors.city}
                        sx={
                          initialValueForFields?.billingAddressCity &&
                          initialValueForFields?.billingAddressCity['color'] ==
                            'red'
                            ? { color: 'red' }
                            : {}
                        }
                      />
                    ) : (
                      <SelectOld
                        sx={{
                          maxWidth: '350px',
                        }}
                        label='Country'
                        placeholder='Enter Country'
                        id='country'
                        name='country'
                        showOptionLabel
                        value={{ label: rest?.values?.country }}
                        setFormikValue={(id, option) => {
                          if (rest.values['country'] !== option?.value) {
                            rest.setFieldValue(id, option?.value);
                            let payload = {
                              country: option?.value,
                            };
                            getStateDataFunc(payload);
                          }
                        }}
                        error={
                          rest?.touched?.country &&
                          Boolean(rest?.errors?.country)
                        }
                        helperText={
                          rest?.touched?.country && rest?.errors?.country
                        }
                        allowRemoveIcon={false}
                        options={(() => {
                          var arr = [];
                          countryOpt.forEach((option) => {
                            arr.push({
                              label: option.name,
                              value: option.code,
                            });
                          });
                          return arr;
                        })()}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {clientOptions.additionalSettings?.csc_dropdown == 0 ? (
                      <TextField
                        placeholder='Enter State / Province'
                        label='State / Province'
                        id={state}
                        name={state}
                        value={rest.values.state}
                        onChange={(e) => {
                          if (
                            e.target.value.length <=
                            clientOptions.additionalSettings
                              ?.state_character_length
                          ) {
                            rest.setFieldValue(state, e.target.value);
                            rest.setFieldError(state, '');
                            rest.setFieldTouched(state, true);
                          } else {
                            rest.setFieldError(
                              state,
                              `State / Province name cannot exceed ${clientOptions.additionalSettings?.state_character_length} characters`
                            );
                          }
                        }}
                        error={rest.touched.state && Boolean(rest.errors.state)}
                        helperText={rest.touched.state && rest.errors.state}
                        inputProps={{
                          maxLength:
                            clientOptions?.additionalSettings?.state_length > 0
                              ? clientOptions?.additionalSettings?.state_length
                              : undefined,
                        }}
                        sx={
                          initialValueForFields?.billingAddressState &&
                          initialValueForFields?.billingAddressState['color'] ==
                            'red'
                            ? { color: 'red' }
                            : {}
                        }
                      />
                    ) : (
                      <SelectOld
                        sx={{
                          maxWidth: '350px',
                        }}
                        label='State / Province'
                        placeholder='Select State / Province'
                        id={state}
                        name={state}
                        value={{ label: rest?.values?.state }}
                        showOptionLabel
                        setFormikValue={(id, option) => {
                          if (rest.values[state] !== option?.value) {
                            rest.setFieldValue(id, option?.value);
                            let payload = {
                              country: rest.values['country'],
                              state: option?.value,
                            };
                            getCityDataFunc(payload);
                          }
                        }}
                        error={
                          rest?.touched?.state && Boolean(rest?.errors?.state)
                        }
                        helperText={rest?.touched?.state && rest?.errors?.state}
                        allowRemoveIcon={false}
                        options={(() => {
                          var arr = [];
                          stateOpt.forEach((option) => {
                            arr.push({
                              label: option.name,
                              value: option.code,
                            });
                          });
                          return arr;
                        })()}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      placeholder='Enter Zip / Postal Code'
                      label='Zip / Postal Code'
                      required
                      id={zipCode}
                      name={zipCode}
                      value={rest.values.zipCode}
                      onChange={rest.handleChange}
                      error={
                        rest.touched.zipCode && Boolean(rest.errors.zipCode)
                      }
                      helperText={rest.touched.zipCode && rest.errors.zipCode}
                      sx={
                        initialValueForFields?.billingAddressZip &&
                        initialValueForFields?.billingAddressZip['color'] ==
                          'red'
                          ? { color: 'red' }
                          : {}
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {clientOptions.additionalSettings?.csc_dropdown == 0 ? (
                      <TextField
                        required
                        id='country'
                        name='country'
                        label='Country'
                        placeholder='Enter Country'
                        value={rest?.values?.country}
                        onChange={(e) => {
                          if (
                            e.target.value.length <=
                            clientOptions.additionalSettings
                              ?.country_character_length
                          ) {
                            rest.setFieldValue('country', e.target.value);
                            rest.setFieldError('country', '');
                            if (e.target.value.length > 1) {
                              rest.setFieldTouched('country', true);
                            }
                          } else {
                            rest.setFieldError(
                              'country',
                              `Country name cannot exceed ${clientOptions.additionalSettings?.country_character_length} characters`
                            );
                          }
                        }}
                        error={
                          rest?.touched?.country &&
                          Boolean(rest?.errors?.country)
                        }
                        helperText={
                          rest?.touched?.country && rest?.errors?.country
                        }
                        inputProps={{
                          maxLength:
                            clientOptions?.additionalSettings?.country_length >
                            0
                              ? clientOptions?.additionalSettings
                                  ?.country_length
                              : undefined,
                        }}
                        sx={
                          initialValueForFields?.billingAddressCountry &&
                          initialValueForFields?.billingAddressCountry[
                            'color'
                          ] == 'red'
                            ? { color: 'red' }
                            : {}
                        }
                      />
                    ) : (
                      <SelectOld
                        sx={{
                          maxWidth: '350px',
                        }}
                        label='City'
                        id={city}
                        name={city}
                        placeholder='Enter City'
                        value={{ label: rest?.values?.city }}
                        showOptionLabel
                        setFormikValue={(id, option) => {
                          rest.setFieldValue(id, option?.value);
                        }}
                        error={
                          rest?.touched?.city && Boolean(rest?.errors?.city)
                        }
                        helperText={rest?.touched?.city && rest?.errors?.city}
                        allowRemoveIcon={false}
                        options={(() => {
                          var arr = [];
                          cityOpt.forEach((option) => {
                            arr.push({
                              label: option.name,
                              value: option.name,
                            });
                          });
                          return arr;
                        })()}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </MyModal>
  );
};

AddBillingAddressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  initialValueForFields: PropTypes.object,
  title: PropTypes.string.isRequired,
  sendApiRequest: PropTypes.func.isRequired,
  countryOptions: PropTypes.array.isRequired,
  edit: PropTypes.bool.isRequired,
  isNotificationAction: PropTypes.bool,
  handleDeclineBtnClick: PropTypes.func,
};

AddBillingAddressModal.defaultProps = {
  initialValueForFields: {},
  edit: false,
  isNotificationAction: false,
  handleDeclineBtnClick: () => {},
};

export default AddBillingAddressModal;
