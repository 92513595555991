export const additionalSettingsFieldMapping = {
  city_character_length: 'city',
  country_character_length: 'country',
  country_length: 'country',
  state_character_length: 'state',
  state_length: 'state',
  street2_character_length: 'address',
  street_character_length: 'address_2',

  city: ['city_character_length'],
  country: ['country_character_length'],
  state: ['state_character_length'],
  address: ['street2_character_length'],
  address_2: ['street_character_length'],
};
